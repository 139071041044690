<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Banco Central - Consultar</h4>
            <div class="small text-muted">Realizar una consulta de situación crediticia a la base de datos del Banco Central</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <b-row>
          <b-col sm="2">
            <b-form-group label="Tipo de consulta">
              <b-form-radio-group                
                v-model="form.filtro"
                :options="optionsTipo"                
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col sm="2" v-if="form.filtro=='CUIT'">
            <b-form-group label="CUIT/CUIL">
              <b-form-input type="text"
                            size="sm"
                            v-model="form.cuil"                            
                            placeholder="Ingresar un CUIT/CUIL"
                            v-on:keyup.enter.native="query()">
              </b-form-input>
            </b-form-group>          
          </b-col>
          <b-col sm="2" v-if="form.filtro=='DNI'">
            <b-form-group label="Documento">
              <b-form-input type="text"
                            size="sm"
                            v-model="form.documento"                            
                            placeholder="Ingresar un DNI"
                            v-on:keyup.enter.native="query()">
              </b-form-input>
            </b-form-group> 
          </b-col>
          <b-col sm="2" v-if="form.filtro=='DNI'">
            <b-form-group label="Sexo">
              <b-form-radio-group                
                v-model="form.sexo"
                :options="optionsSexo"                
              ></b-form-radio-group>
            </b-form-group>                                            
          </b-col>
          <b-col sm="2">
            <b-button type="button" size="sm" variant="dark" class="bcra-query-button-filter" @click="query()">
              <i class="fa fa-filter"></i> 
              Consultar
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      
      <b-card v-if="informeOk">                   
        <div v-if="identidadOk">    
          <b>Documento</b>: {{this.informe.identidad[0].NroDoc}}
          <i class="cui-chevron-right"></i><i class="cui-chevron-right"></i>
          <a class="bcra-query-link-cuitonlinea" @click="verInfoCuit(informe.identidad[0].NroDoc)">
            Conocer mas sobre este DNI
          </a><br>            
          <b>Nombre y Apelldio</b>: {{this.informe.identidad[0].ApellidoNombre}}<br>
          <b>Sexo</b>: <span v-if="this.informe.identidad[0].sexo=='M'">Masculino</span><span v-else>FEMENINO</span><br>
          <b>Fecha Nacimiento</b>: {{moment(this.informe.identidad[0].fnac).format('DD/MM/YYYY')}}
        </div>
        <div v-else>
          <div v-if="informeOk">            
            <b>CUIT:</b> {{this.informe.request[0].pedido_cuil}} 
            <i class="cui-chevron-right"></i><i class="cui-chevron-right"></i>
            <a class="bcra-query-link-cuitonlinea" @click="verInfoCuit(informe.request[0].pedido_cuil)">
              Conocer mas sobre este cuit
            </a>            
          </div>
        </div>              
      </b-card>
               
      <b-row v-if="informeOk">   
        <b-col md="4">
          <b-row>       
            <b-col sm="12">     
              <b-card header-bg-variant="dark" header-text-variant="white" header="Cheques rechazados">                
                <div v-if="chequeOk && resultOk">              
                  <span class="bcra-query-leyenda-importante">¡ATENCIÓN Cheques rechazados</span><br>
                  <br>
                  <b>Rechazos:</b> {{ this.informe.result[0].CHEQUES_Total_rechazos }}<br>
                  <b>Total rechazado:</b> $ {{ formatNumber(this.informe.result[0].CHEQUES_Monto_rechazos_sin_fondos) }}<br>
                  <b>Sin fondos:</b> {{ this.informe.result[0].CHEQUES_Total_rechazos_sin_fondos }}<br>
                  <b>Sin fondos levantados:</b> {{ this.informe.result[0].CHEQUES_Total_levantados_sin_fondos }} 
                  <span class="bcra-query-porcentaje-cheques" v-if="this.informe.result[0].CHEQUES_Total_rechazos_sin_fondos>0">
                    ({{ ((this.informe.result[0].CHEQUES_Total_levantados_sin_fondos / this.informe.result[0].CHEQUES_Total_rechazos_sin_fondos) *100).toFixed(2) + '%'}})
                  </span>
                  <span class="bcra-query-porcentaje-cheques" v-else>(0,00 %)</span><br> 
                  <b>Vicios formales:</b> {{ this.informe.result[0].CHEQUES_Total_rechazos_vicios_formales }}<br>
                  <b>Vicios formales levantados:</b> {{ this.informe.result[0].CHEQUES_Total_levantados_vicios_formales }}
                  <span class="bcra-query-porcentaje-cheques" v-if="this.informe.result[0].CHEQUES_Total_rechazos_vicios_formales>0">
                    ({{ ((this.informe.result[0].CHEQUES_Total_levantados_vicios_formales / this.informe.result[0].CHEQUES_Total_rechazos_vicios_formales) *100).toFixed(2) + '%'}})
                  </span>
                  <span class="bcra-query-porcentaje-cheques" v-else>(0,00 %)</span><br> 
                  
                  <hr>
                  
                  <div v-if="ultimoCheque">                             
                    <h5>Último rechazo</h5>
                    <b>Fecha:</b> {{this.formatearFecha(this.ultimoCheque.F_RECHAZO).format('DD/MM/YYYY')}}<br>
                    <b>Nro Cheque:</b> {{this.ultimoCheque.CHEQUE}}<br>
                    <b>Importe:</b> $ {{formatNumber(this.ultimoCheque.IMPORTE)}}<br>
                    <b>Levantado:</b> <span v-if="this.ultimoCheque.LEVANTADO"> {{this.formatearFecha(this.ultimoCheque.LEVANTADO).format('DD/MM/YYYY')}}</span>
                                      <span v-else> -</span><br>                
                  </div>   
                </div>
                <div v-else>
                  <b-alert variant="info" show>
                    <b>No posee cheques rechazados</b>
                  </b-alert>
                </div>                                                 
              </b-card>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12">
              <b-card header-bg-variant="dark" header-text-variant="white" header="Listado de cheques rechazados">                             
                <b-table class="mb-0"
                      responsive="sm"
                      head-variant="dark"
                      :hover="true"
                      :small="true"
                      :fixed="false"
                      :items="table.items"
                      :fields="table.fields"                                          
                      :current-page="table.currentPage"
                      :per-page="table.perPage"                      
                      v-if="chequeOk">

                    <template v-slot:cell(f_rechazo)="data">                    
                      <div v-if="!data.item.LEVANTADO">                    
                        {{ formatearFecha(data.item.F_RECHAZO).format('DD/MM/YYYY') }}
                      </div>
                      <div v-else> 
                        <div style="text-decoration:line-through;">                   
                          {{ formatearFecha(data.item.F_RECHAZO).format('DD/MM/YYYY') }}
                        </div>
                        <b>Levantado:</b> {{ formatearFecha(data.item.LEVANTADO).format('DD/MM/YYYY') }}
                      </div>
                    </template>

                    <template v-slot:cell(cheque)="data">
                      {{data.item.CHEQUE}}                    
                    </template>                                         
                    
                    <template v-slot:cell(importe)="data">
                      $ {{ formatNumber(data.item.IMPORTE) }}
                    </template>

                    <template v-slot:cell(cuil)="data">
                      <div v-if="data.item.CUIL">
                        <i class="cui-chevron-right"></i>
                        <i class="cui-chevron-right"></i> 
                        <a class="bcra-query-link-cuitonlinea" @click="verInfoCuit(data.item.CUIL)">{{data.item.CUIL}}</a>
                      </div>
                      <div v-if="data.item.CUIT_VINCULADO">
                        <i class="cui-chevron-right"></i>
                        <i class="cui-chevron-right"></i> 
                        <a class="bcra-query-link-cuitonlinea" @click="verInfoCuit(data.item.CUIT_VINCULADO)">{{data.item.CUIT_VINCULADO}}</a>
                      </div>                                            
                    </template>
                    
                </b-table>  
                <div v-else>
                  <b-alert variant="info" show>
                    <b>No posee cheques rechazados</b>
                  </b-alert>
                </div>  

                <nav v-if="chequeOk" class="mt-3">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="getRowCount(table.items)"
                                :per-page="table.perPage"
                                v-model="table.currentPage" />
                </nav>

              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="3">
          <b-row>   
            <b-col sm="12">
              <b-card header-bg-variant="dark" header-text-variant="white" header="Entidades y montos por situación">            
                <div v-if="chart.situacion.visible">                                    
                  <b-row>
                    <b-col sm="12" v-if="dataOK">
                      <pie-chart :chartData="chart.situacion.chartdata" />
                    </b-col>
                    <b-col sm="12" class="mt-3">    
                      <b-table-simple hover small responsive>              
                        <b-thead head-variant="dark">
                          <b-tr>
                            <b-th>Situación</b-th>
                            <b-th class="text-center">Entidades</b-th>
                            <b-th class="text-center">Monto</b-th>
                          </b-tr>
                        </b-thead> 
                        <b-tbody>
                          <b-tr>
                            <b-th variant="secondary">Situación 1</b-th>
                            <b-td class="text-center">{{this.informe.result[0].BCRA_actual_cantidad_01}}</b-td>
                            <b-td class="text-center">{{formatNumber(this.informe.result[0].BCRA_actual_monto_01)}}k</b-td>
                          </b-tr>                                             
                          <b-tr>
                            <b-th variant="secondary">Situación 2</b-th>
                            <b-td class="text-center">{{this.informe.result[0].BCRA_actual_cantidad_02}}</b-td>
                            <b-td class="text-center">{{formatNumber(this.informe.result[0].BCRA_actual_monto_02)}}k</b-td>
                          </b-tr>    
                          <b-tr>
                            <b-th variant="secondary">Situación 3</b-th>
                            <b-td class="text-center">{{this.informe.result[0].BCRA_actual_cantidad_03}}</b-td>
                            <b-td class="text-center">{{formatNumber(this.informe.result[0].BCRA_actual_monto_03)}}k</b-td>
                          </b-tr>    
                          <b-tr>
                            <b-th variant="secondary">Situación 4</b-th>
                            <b-td class="text-center">{{this.informe.result[0].BCRA_actual_cantidad_04}}</b-td>
                            <b-td class="text-center">{{formatNumber(this.informe.result[0].BCRA_actual_monto_04)}}k</b-td>
                          </b-tr>    
                          <b-tr>
                            <b-th variant="secondary">Situación 5</b-th>
                            <b-td class="text-center">{{this.informe.result[0].BCRA_actual_cantidad_05}}</b-td>
                            <b-td class="text-center">{{formatNumber(this.informe.result[0].BCRA_actual_monto_05)}}k</b-td>
                          </b-tr>    
                          <b-tr>
                            <b-th variant="secondary">Situación 6</b-th>
                            <b-td class="text-center">{{this.informe.result[0].BCRA_actual_cantidad_06}}</b-td>
                            <b-td class="text-center">{{formatNumber(this.informe.result[0].BCRA_actual_monto_06)}}k</b-td>
                          </b-tr>                                                                                                                                                                               
                        </b-tbody>                                                  
                      </b-table-simple>      
                    </b-col>                    
                  </b-row>                  
                </div>                            
              </b-card>
            </b-col>                
            <b-col sm="12">
              <b-card header-bg-variant="dark" header-text-variant="white" header="Histórico de situación">
                <b>Periodo:</b> {{formatearPeriodo(this.informe.result[0].periodo)}}<br>
                <b>En situación 1 desde:</b> <span v-if="this.informe.result[0].Sit1_Desde"> 
                  {{formatearPeriodo(this.informe.result[0].Sit1_Desde)}}                  
                </span>
                <span v-else> Desconocido</span><br>                        
                <br>            
                <b-table-simple hover small responsive>
                  <b-thead head-variant="dark">
                    <b-tr>
                      <b-th>Período</b-th>
                      <b-th class="text-center">Situación</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-th variant="secondary">Situación actual</b-th>
                      <b-td class="text-center">{{this.informe.result[0].BCRA_actual_max_situacion}}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th variant="secondary">Hace 3 Meses</b-th>
                      <b-td class="text-center">{{this.informe.result[0].BCRA_max_situacion_3M}}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th variant="secondary">Hace 6 Meses</b-th>
                      <b-td class="text-center">{{this.informe.result[0].BCRA_max_situacion_6M}}</b-td>
                    </b-tr>       
                    <b-tr>
                      <b-th variant="secondary">Hace 9 Meses</b-th>
                      <b-td class="text-center">{{this.informe.result[0].BCRA_max_situacion_9M}}</b-td>
                    </b-tr>       
                    <b-tr>
                      <b-th variant="secondary">Hace 12 Meses</b-th>
                      <b-td class="text-center">{{this.informe.result[0].BCRA_max_situacion_12M}}</b-td>
                    </b-tr>                                                                                
                  </b-tbody>
                </b-table-simple>                             
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="5">
          <b-row>          
            <b-col sm="12">                     
              <b-card v-if="periodoOk" header-bg-variant="dark" header-text-variant="white" header="Histórico por entidades">                                        
                <div v-for="(value, key) in this.informe.periodo" v-bind:key="key">

                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block href="#" v-b-toggle="'accordion-' + key" variant="secondary" class="text-left">
                      <b-row>
                        <b-col md="8">
                          <b>Periodo:</b> {{formatearPeriodo(value.PERIODO)}}<br> 
                          <b>Banco:</b> {{value.ENTIDAD}}
                        </b-col>
                        <b-col md="4">
                          <b>Monto:</b> {{formatNumber(value.MONTO)}}k<br>
                          <b>Situacion:</b> {{value.SITU}}
                        </b-col>
                      </b-row>
                    </b-button>
                  </b-card-header>
                  <b-collapse :id="'accordion-'+ key" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <bar-chart :chartData="chart.entidades[value.ENTIDAD].chartdata" />                            
                    </b-card-body>
                  </b-collapse>

                </div>                  
              </b-card>
            </b-col>
          </b-row>
        </b-col>        
      </b-row>

      <b-card>
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Funciones from '@/handler/funciones'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment' 
  import BarChart from './chart/bar'    
  import PieChart from './chart/pie'    
  
  export default {
    components: {
      BarChart,
      PieChart
    },    
    data: () => {
      return {
        access: {
          module_id: Modules.BCRA,
          profile_id: Profiles.PERSONAL,
          view_reference: 'query',
          elements: {}
        },   
        table : {
          items: [],
          fields: [
            {key: 'f_rechazo', label: 'Rechazo', sortable: true, class:"align-middle"},            
            {key: 'cheque', label: 'Nº Cheque', sortable: true, class:"align-middle"},            
            {key: 'importe', label:'Importe', sortable: true, class:"align-middle"},
            {key: 'cuil', label:'Responsanle', class:"align-middle"},            
          ],          
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 10,          
        },                
        form: {
          filtro:'CUIT',
          documento:'',
          sexo:'M',
          cuil:'',
        },        
        optionsTipo: [{
          value: 'CUIT',
          text: 'CUIT/CUIL'
        },{
          value:'DNI',
          text:'DNI',
        }],
        optionsSexo: [{
          value: 'M',
          text: 'Masculino'
        },{
          value:'F',
          text:'Femenino',
        }],                
        informe: null,                
        ultimoCheque: null,                
        chart: {
          situacion: {  
            visible: false,
            chartdata: {},
          },
          entidades: [],         
        },
        primaryColor: ''
      }
    },
    computed: {
      informeOk() {
        if(this.informe) {
          if(this.informe.request.length) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
      identidadOk() {
        if(this.informeOk) {
          if(this.informe.identidad) {
            if(this.informe.identidad.length) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        } else {
          return false
        }        
      },
      chequeOk() {
        if(this.informeOk) {
          if(this.informe.cheques) {
            if(this.informe.cheques.length) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        } else {
          return false
        }
      },
      resultOk() {
        if(this.informeOk) {
          if(this.informe.result) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
      periodoOk() {
        if(this.informeOk) {
          if(this.informe.periodo) {
            if(this.informe.periodo.length) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        } else {
          return false
        }      
      },
      dataOK() {
        var arrSituacion = this.informe.result[0]
        
        return arrSituacion.BCRA_actual_monto_01>0 || 
          arrSituacion.BCRA_actual_monto_02>0 ||
          arrSituacion.BCRA_actual_monto_03>0 || 
          arrSituacion.BCRA_actual_monto_04>0 || 
          arrSituacion.BCRA_actual_monto_05>0 || 
          arrSituacion.BCRA_actual_monto_06>0;      
      }
    },
    mounted() {
      this.setup()
      
      var params = this.$route.params.params       
      if(params) {
        this.queryByResponse(params)
      }            
    },
    methods: {
      getRowCount (items) {
        return items.length
      },      
      query() {        
        if(this.form.filtro == 'DNI') {
          if(this.form.documento.length==0) {
            this.$awn.alert("No se cargo el DNI");  
            return false
          }
          
          var result = serviceAPI.queryBCRADNI(this.form)          
        } else {        
          if(this.form.cuil.length==0) {
            this.$awn.alert("No se cargo el CUIT/CUIL");
            return false  
          } 
          
          var result = serviceAPI.queryBCRACUIT(this.form)          
        }
        
        let loader = this.$loading.show()        
        result.then((response) => {          
          var data = response.data          
          this.informe = JSON.parse(data)
          this.table.items = this.informe.cheques          
          
          this.reset()
          
          this.graficarHistorico()
          this.graficarSituacion()          
          this.quitarDuplicadosCheques()          
          this.ultimoChequesRechazados()                                    
                   
          this.$awn.success("Consulta realizada con éxito");
          loader.hide()
        })
        .catch(error => {                   
          this.$awn.alert(Error.showError(error));
          loader.hide()
        });              
      },
      queryByResponse(response){
        let loader = this.$loading.show()        
        var data = JSON.parse(response)      
        this.informe = data
        this.table.items = this.informe.cheques          
        
        this.reset()
        
        this.graficarHistorico()
        this.graficarSituacion()          
        this.quitarDuplicadosCheques()          
        this.ultimoChequesRechazados()                                    
                
        this.$awn.success("Consulta realizada con éxito");
        
        loader.hide()
      },
      reset() {        
        this.form.documento = ''
        this.form.cuil = ''
      },      
      graficarHistorico(){            
        var arrPeriodo = this.informe.periodo
        var arrHistorico = this.informe.historico
        var arrFinal = []                
        
        if(arrPeriodo) {                    
          arrPeriodo.forEach((value, key) => {                
            this.chart.entidades[value.ENTIDAD] =  {
              chartdata: {
                labels: [],
                datasets: [{
                  backgroundColor: [],
                  data: [],
                }],
              }
            }    
          })
        }
                
        if(arrHistorico) {          
          arrHistorico.forEach((value, key) => {                        
            var label = value.PERIODO.substring(4)+'/'+value.PERIODO.substring(0,4)
            var monto = value.MONTO
            
            if(this.chart.entidades[value.ENTIDAD] !== undefined) {                      
              this.chart.entidades[value.ENTIDAD].chartdata.labels.push(label)                          
              this.chart.entidades[value.ENTIDAD].chartdata.datasets[0].data.push(monto)
              
              this.chart.entidades[value.ENTIDAD].chartdata.datasets[0].label = "Montos"
              this.chart.entidades[value.ENTIDAD].chartdata.datasets[0].backgroundColor = this.primaryColor

            } else {              
              
              this.chart.entidades[value.ENTIDAD] =  {
                chartdata: {
                  labels: [],
                  datasets: [{
                    backgroundColor: [],
                    data: [],
                  }],
                }
              }    

              this.chart.entidades[value.ENTIDAD].chartdata.labels.push(label)                          
              this.chart.entidades[value.ENTIDAD].chartdata.datasets[0].data.push(monto)              
            }
          })          
        }         
      },
      graficarSituacion(){    
        
        this.chart.situacion.chartdata = {
          labels: [],
          datasets: [{
            backgroundColor: [],
            data: [],
          }],
        }
        
        var arrSituacion = this.informe.result[0]              
        
        this.chart.situacion.chartdata.labels.push('Situación 1')
        this.chart.situacion.chartdata.labels.push('Situación 2')
        this.chart.situacion.chartdata.labels.push('Situación 3')
        this.chart.situacion.chartdata.labels.push('Situación 4')
        this.chart.situacion.chartdata.labels.push('Situación 5')
        this.chart.situacion.chartdata.labels.push('Situación 6')        
        
        this.chart.situacion.chartdata.datasets[0].backgroundColor.push('#47b54b')
        this.chart.situacion.chartdata.datasets[0].backgroundColor.push('#aeec8c')
        this.chart.situacion.chartdata.datasets[0].backgroundColor.push('#b9eb40')
        this.chart.situacion.chartdata.datasets[0].backgroundColor.push('#ffd949')
        this.chart.situacion.chartdata.datasets[0].backgroundColor.push('#f8764b')
        this.chart.situacion.chartdata.datasets[0].backgroundColor.push('#ec3f48')        

        this.chart.situacion.chartdata.datasets[0].data.push(arrSituacion.BCRA_actual_monto_01);
        this.chart.situacion.chartdata.datasets[0].data.push(arrSituacion.BCRA_actual_monto_02);
        this.chart.situacion.chartdata.datasets[0].data.push(arrSituacion.BCRA_actual_monto_03);
        this.chart.situacion.chartdata.datasets[0].data.push(arrSituacion.BCRA_actual_monto_04);
        this.chart.situacion.chartdata.datasets[0].data.push(arrSituacion.BCRA_actual_monto_05);
        this.chart.situacion.chartdata.datasets[0].data.push(arrSituacion.BCRA_actual_monto_06);
        
        this.chart.situacion.visible = true
      },    
      quitarDuplicadosCheques(){        
        var arrCheques = this.informe.cheques
        var arrTemporal = []
        var arrFinal = []

        arrCheques.forEach((value, key) => {          
          if(arrTemporal['c' + value.CHEQUE.toString()]==undefined) {
            arrFinal.push(value)
          }
          arrTemporal['c' + value.CHEQUE.toString()] = value           
        })
        this.table.items = arrFinal        
      },
      ultimoChequesRechazados(){      
        var arrCheques = this.informe.cheques

        var select =  null
        var fechaMayor =  moment('2000-01-01')
        
        arrCheques.forEach((value, key) => {  
          if(value.F_RECHAZO!=null) {                         
            var fechaCheque = this.formatearFecha(value.F_RECHAZO)
  
            if(fechaCheque>fechaMayor){                        
              fechaMayor = fechaCheque
              select = value
            }          
          }
        });
        this.ultimoCheque = select        
      }, 
      formatearFecha(valor){    
        var c_anio = valor.substring(0,4)
        var c_mes = valor.substring(4,6)
        var c_dia = valor.substring(6)
        return moment(c_anio + '-' + c_mes + '-' + c_dia)        
      },
      formatearPeriodo(valor){    
        var c_anio = valor.substring(0,4)
        var c_mes = valor.substring(4,6)        
        return c_mes + '/ ' + c_anio        
      },      
      formatNumber(valor) {
        return valor.toLocaleString()
      },
      verInfoCuit(cuil) {        
        window.open('https://www.cuitonline.com/search.php?q=' + cuil, '_blank')        
      },  
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary                              
        }
      },               
    }
  }
</script>

<style>
  .bcra-query-leyenda-importante {
    background: red;
    color: white;
    font-weight: bold;
    font-size: 18px;
    padding: 5px;
  }
  .bcra-query-porcentaje-cheques {
    color: gray;
    font-style: italic;
    font-weight: 400;
  }
  .bcra-query-link-cuitonlinea {
    color: red !important;
    font-weight: 700 !important;
    text-decoration: underline !important;
    cursor: pointer !important;
  }
  .bcra-query-button-filter {
    margin-top: 30px;
  }  
</style>



