<script>
  import { Bar, mixins } from 'vue-chartjs'
  const { reactiveProp } = mixins

  export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: {
      chartData: {
        type: Object,
        default: {}
      },  
    },
    data() {
      return {
        options: {
          responsive: true,
          maintainAspectRatio: true,
          scales: {        
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                beginAtZero: true,                
              }               
            }],                    
            yAxes: [{
              display: true,
              ticks: {
                beginAtZero: true,                
              }
            }]
          }
        }
      }
    },
    mounted () {        
      this.render()
    },
    watch: {
      chartData: function() {
        this.$data._chart.update()
      },
    }, 
    methods: {
      render() {
        this.renderChart(this.chartData, this.options) 
      }
    }
  }
</script>
